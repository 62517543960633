//schema's - change here if the number at the end updates.
const eventActionSchema = "iglu:com.thepetlabco/event_action/jsonschema/3-0-0";
const appContextSchema = "iglu:com.thepetlabco/app_context/jsonschema/1-0-0";
const productContextSchema =
  "iglu:com.thepetlabco/product_context/jsonschema/1-0-5";
const discountCodeContextSchema =
  "iglu:com.thepetlabco/discount_code_context/jsonschema/1-0-0";

export const userViewEvent = (object = "page") => {
  return {
    schema: eventActionSchema,
    data: {
      subject: "user",
      action: "viewed",
      object: object,
      categories: ["upsell"]
    }
  };
};

export const UserViewedPage = selectorData => {
  const { offers = {} } = selectorData;
  const countryCode = offers[0]?.checkoutData?.country;
  const context = [
    {
      schema: appContextSchema,
      data: {
        app_name: `Upsell-${countryCode}`,
        app_environment:
          process.env.GATSBY_BUILDER_ENVIRONMENT === "staging"
            ? "staging"
            : "production"
      }
    }
  ];

  offers.length &&
    offers?.forEach(item => {
      context.push({
        schema: productContextSchema,
        data: {
          product_id: item.checkoutData?.product_id,
          product_name: item?.checkoutData?.title,
          price: item?.checkoutData?.price,
          variant_id: item?.checkoutData?.variant_id
        }
      });
      context.push({
        schema: discountCodeContextSchema,
        data: {
          discount_code: item?.discountCode,
          discount_percentage: item?.discountRate,
          discount_amount: (
            item?.checkoutData?.price - item?.checkoutData?.discounted_price
          ).toFixed(2)
        }
      });
    });

  return {
    context
  };
};
