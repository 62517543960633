import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { builder, Builder, BuilderComponent } from "@builder.io/react";
import { Helmet } from "react-helmet";
import { Provider } from "../context/Context";
import getOrderData from "../functions/orderData";
import { getSalesTaxes, getSalesTaxesV2 } from "../functions/salesTaxes";
import submitOrders from "../functions/submitOrders";
import { initializeDataLayer } from "../functions/dataLayer";
import Loading from "../components/Loading/Loading";
import CookieCheck from "../components/CookieCheck/CookieCheck";
import StagingIndicator from "../components/StagingIndicator/StagingIndicator";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "../../static/css/upsell.css";
import { addDataLayer } from "../utils/dataLayers";
import { UserViewedPage, userViewEvent } from "../utils/eventTracking";

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = "PetLab Co.";
const defaultDescription = "Supplies, Treats, Snacks & Chews - Join The Pack";

const UpsellTemplate = props => {
  const {
    data,
    "*": userToken,
    pageContext: { forceCheckoutApiVersion2 = false }
  } = props;

  const oneUpsell = data?.allBuilderModels.oneUpsell;
  const content = oneUpsell?.content;

  const {
    countdownDuration = 900,
    title: pageTitle,
    description: pageDescription,
    settings = {}
  } = content?.data;

  const selectorData = data?.upsellCheckoutData;
  const { checkoutApiVersion2 } = selectorData;
  const isCheckoutApiVersion2 = forceCheckoutApiVersion2
    ? true
    : checkoutApiVersion2;

  const {
    showLoading = false,
    skipOffers = false,
    offerByVariantId,
    checkCookie = false
  } = settings;

  const endTime = new Date();
  endTime.setSeconds(endTime.getSeconds() + countdownDuration);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [upsellCart, setUpsellCart] = useState([]);
  const [preFetch, setPreFetch] = useState(false);
  const [salesTaxes, setSalesTaxes] = useState({
    label: "Sales tax",
    tax: [],
    total: 0,
    tax_rate: 0
  });
  const [orderData, setOrderData] = useState({});
  const [displayLoading, setDisplayLoading] = useState(
    showLoading && !Builder.isEditing
  );
  const [showOrderSummary, setShowOrderSummary] = useState(false);

  const context = {
    selectorData: selectorData,
    currentIndex: currentIndex,
    setCurrentIndex: setCurrentIndex,
    buttonClicked: buttonClicked,
    setButtonClicked: setButtonClicked,
    userToken: userToken,
    upsellCart: upsellCart,
    setUpsellCart: setUpsellCart,
    salesTaxes: salesTaxes,
    orderData: orderData,
    countDownEnd: endTime,
    showOrderSummary: showOrderSummary,
    setShowOrderSummary: setShowOrderSummary,
    isCheckoutApiVersion2: isCheckoutApiVersion2
  };

  useEffect(() => {
    const { discounted_price } = selectorData.offers[currentIndex]
      ?.checkoutData || { discounted_price: 0 };
    if (isCheckoutApiVersion2 && orderData?.shipping_address) {
      getSalesTaxesV2(discounted_price, orderData?.shipping_address).then(
        result => {
          setSalesTaxes(result);
        }
      );
    }
  }, [currentIndex, orderData, setSalesTaxes, selectorData]);

  useEffect(() => {
    if (skipOffers && !Builder.isEditing) {
      submitOrders(userToken, [], {}, isCheckoutApiVersion2);
      return;
    }

    if (!preFetch) {
      // get order data
      getOrderData(userToken, isCheckoutApiVersion2).then(data => {
        setOrderData(data);
        initializeDataLayer(data, userToken, isCheckoutApiVersion2);

        let found = false;
        if (offerByVariantId) {
          offerByVariantId.every(({ variantId, offerIndex }) => {
            const item = data?.items.find(
              ({ variant_id }) => variant_id === variantId
            );
            if (item) {
              found = true;
              setCurrentIndex(offerIndex);
              if (typeof selectorData?.offers[offerIndex] === "undefined") {
                submitOrders(userToken, upsellCart, {}, isCheckoutApiVersion2);
              }
              return false;
            }
            return true;
          });
        }
        if (!offerByVariantId || !found) {
          setDisplayLoading(false);
        }
      });

      // get sales taxes
      if (!isCheckoutApiVersion2)
        getSalesTaxes(selectorData, userToken).then(data => {
          setSalesTaxes(data);
        });

      setPreFetch(true);
      addDataLayer(userViewEvent("page"), UserViewedPage(selectorData));
    }
  }, [
    currentIndex,
    selectorData,
    preFetch,
    setPreFetch,
    userToken,
    setSalesTaxes,
    setOrderData,
    offerByVariantId,
    setCurrentIndex,
    upsellCart,
    skipOffers
  ]);

  return (
    <Provider value={context}>
      <ReactNotifications />
      <Helmet>
        <title>{pageTitle || defaultTitle}</title>
        <meta
          name="description"
          content={pageDescription || defaultDescription}
        />
        <meta name="funnel-id" content={content.id} />
        <meta name="product-selector-id" content={content.data.selector.id} />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <BuilderComponent model="upsell" content={content} />
      {displayLoading && <Loading />}
      {checkCookie && <CookieCheck />}
      {process.env.GATSBY_UPSELL_STAGING && <StagingIndicator />}
    </Provider>
  );
};

export default UpsellTemplate;

export const upsellQuery = graphql`
  query UpsellQuery($upsellId: String!) {
    allBuilderModels {
      oneUpsell(
        query: { id: $upsellId }
        options: { cachebust: true, includeRefs: true }
      ) {
        content
      }
    }

    upsellCheckoutData(upsellId: { eq: $upsellId }) {
      checkoutUrl
      currency {
        code
        symbol
      }
      offers {
        checkoutData {
          country
          discounted_price
          image
          price
          perceived_rrp
          product_id
          quantity
          splitFlag
          split_price
          title
          title_index
          variant_id
        }
        discountCode
        discountRate
        displayTitle
        nextIndexNo
        nextIndexYes
        offerId
        productName
        variantId
        productId
        tag
        redirectIfFound
        redirectUrl
      }
      store
      checkout
      checkoutApiVersion2
    }
  }
`;
