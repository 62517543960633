// this method must be called during runtime
// - useEffect
// - onClick events
export const addDataLayer = (event, payload) => {
  const { dataLayer } = window;
  const isPageViewExists = el => el.event === event;
  if (dataLayer && dataLayer.some(isPageViewExists)) {
    return;
  }
  dataLayer?.push({
    event,
    ...payload
  });
};
