import MD5 from "crypto-js/md5";

export const initializeDataLayer = (
  orderData,
  userToken,
  apiVersion2 = false
) => {
  const unix_stamp = Math.floor(Date.now() / 1000);
  const email = apiVersion2 ? orderData?.email : orderData?.customer.email;
  const first_name = apiVersion2
    ? orderData?.shipping_address?.first_name
    : orderData?.customer.first_name;
  const last_name = apiVersion2
    ? orderData?.shipping_address?.last_name
    : orderData?.customer.last_name;
  const cart_total_with_discounts = apiVersion2
    ? orderData?.pricing?.discounted_subtotal
    : orderData?.cart_total_with_discounts;
  const phone_number = apiVersion2
    ? orderData?.phone_number
    : orderData?.customer.phone_number;
  const sms_marketing_consent_checked = apiVersion2
    ? orderData?.sms_marketing_consent_checked
    : orderData?.consent_boolean;
  const checkout_session_id = apiVersion2
    ? orderData?.checkout_session_id
    : orderData?.order_id;
  const discount_codes = apiVersion2
    ? orderData?.discount_codes
    : orderData?.discounts;

  let hash = MD5(
    `${email},${first_name},${cart_total_with_discounts},${unix_stamp},20f1c93a0fd7915399e203e6eb60555a`
  );

  //CAA-293 - FriendBuy Discount Codes
  let friendBuyDiscounts = "";
  let isFriendBuy = false;
  const regex = /^FRND/i;

  if (orderData?.discounts) {
    for (const d of orderData?.discounts) {
      if (regex.test(d)) {
        friendBuyDiscounts = d;
        isFriendBuy = true;
      }
    }
  }

  //PPP-174 - FriendBuy Product Array
  let friendBuyProducts = [];

  if (isFriendBuy) {
    for (let i in orderData?.items) {
      const buildObj = {};
      buildObj["name"] = orderData?.products[i].product_name;
      buildObj["sku"] = orderData?.products[i].SKU;
      buildObj["price"] = orderData?.items[i].price;
      buildObj["quantity"] = orderData?.items[i].quantity;
      friendBuyProducts.push(buildObj);

      i++;
    }
  }

  //PPP-198 - Check if purchase includes SKU like "AEASE"
  let isAEASE = false;
  const aeaseREGEX = /AEASE/;

  for (let i in orderData?.items) {
    if (aeaseREGEX.test(orderData?.products[i].SKU)) {
      isAEASE = true;
    }
    i++;
  }

  const data = {
    event: "transaction",
    ecommerce: {
      purchase: {
        orderid: checkout_session_id,
        revenue: cart_total_with_discounts
      },
      basket: {
        products: orderData?.products
      },
      text_consent: {
        consent_boolean: sms_marketing_consent_checked,
        phone_number: phone_number
      },
      customer_email: email,
      first_name: first_name,
      last_name: last_name,
      unix_timestamp: unix_stamp,
      md5_hash: hash.toString(),
      discount_codes: discount_codes,
      friend_buy_discount_code: friendBuyDiscounts,
      friendbuy_products: friendBuyProducts,
      contains_aease: isAEASE,
      checkout_session_id: userToken
    }
  };
  window?.dataLayer?.push(data);
};
