import React, { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import Loading from "../Loading/Loading";
import Cookies from "universal-cookie";
import { useOrdersData } from "../../hooks/useOrdersData";

const cookies = new Cookies();

const CookieCheck = () => {
  const [init, setInit] = useState(false);
  const { selectorData, userToken } = useContext(Context);
  const orderData = useOrdersData();

  useEffect(() => {
    if (!init && Object.entries(orderData).length > 0) {
      const userCookies = cookies.get(orderData.customer_email);
      if (!userCookies) {
        setInit(true);
        return;
      }
      let found = false;
      selectorData?.offers?.forEach(offer => {
        if (offer.redirectIfFound && userCookies.includes(offer.variantId)) {
          found = true;
          window.location.href = `${offer.redirectUrl}/${userToken}`;
        }
      });
      if (found) {
        return;
      }
      setInit(true);
    }
  }, [selectorData, userToken, orderData, init, setInit]);

  return init ? null : <Loading />;
};

export default CookieCheck;
